import axios from 'axios';

const api = axios.create({
  baseURL: 'https://rocketaviator.com/api',
  headers: { 'Content-Type': 'application/json' },
});

// Attach Authorization header for each request if the token is available
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Register a new user
export const registerUser = (username, email, password, pin) =>
  api.post('/register', { username, email, password, pin });

// Login function with email and pin
export const loginUser = async (email, pin) => {
  const response = await api.post('/login', { email, pin });
  localStorage.setItem('token', response.data.token);
  localStorage.setItem('user_id', response.data.user_id);
  return response;
};

// Fetch user data (Authenticated endpoint)
export const fetchUserData = () => api.get('/user/data');

// Place a bet (Authenticated endpoint) with error handling
export const placeBet = async (playerId, betAmount) => {
  try {
    const response = await api.post('/bet/place-bet', { playerId, betAmount });
    return response.data;
  } catch (error) {
    console.error('Error placing bet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Cash out a bet (Authenticated endpoint) with error handling
export const cashOut = async (playerId, multiplier) => {
  try {
    const response = await api.post('/bet/cash-out', { playerId, multiplier });
    return response.data;
  } catch (error) {
    console.error('Error cashing out:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Logout function to clear session data
export const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
};

export default api;
