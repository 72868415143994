// NavBar.js
import React from 'react';
import './NavBar.css';

const NavBar = ({ multiplier, countdown, history }) => {
  return (
    <div className="navbar">
      <div className="navbar-content">
        <div className="navbar-item">
          {history.slice(0, 20).map((historyItem, index) => (
            <span key={index} className="history-item">
              {historyItem.toFixed(2)}x{" "} {/* Display history item rounded to 2 decimal places */}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
