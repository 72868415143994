import React, { useEffect, useRef } from 'react';

const GameCanvas = ({ multiplier, isCrashed }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const render = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            ctx.save();

            if (isCrashed) {
                // Display crash message
                ctx.fillStyle = 'red';
                ctx.font = 'bold 48px Arial';
                ctx.textAlign = 'center';
                ctx.fillText('CRASHED!', canvasWidth / 2, canvasHeight / 2);
                
                // Display multiplier in bold red
                ctx.font = 'bold 32px Arial';
                ctx.fillText(`${multiplier.toFixed(2)}x`, canvasWidth / 2, canvasHeight / 2 + 50);
            } else {
                // Display multiplier during normal gameplay
                ctx.fillStyle = 'white';
                ctx.font = '24px Arial';
                ctx.textAlign = 'center';
                ctx.fillText(`${multiplier.toFixed(2)}x`, canvasWidth / 2, canvasHeight / 2);
            }

            ctx.restore();
        };

        // Render the initial state
        render();

        return () => {
            // Clean-up logic if needed
        };
    }, [multiplier, isCrashed]);

    return (
        <canvas
            ref={canvasRef}
            width="600"
            height="300"
            style={{
                border: 'none',
            }}
        ></canvas>
    );
};

export default GameCanvas;
