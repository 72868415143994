import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import GameCanvas from './GameCanvas';
import Sidebar from './Sidebar';
import PlayerManager from './PlayerManager';
import NavBar from './NavBar';
import { FaSignOutAlt } from 'react-icons/fa';
import './App.css';

const socket = io('https://rocketaviator.com'); // Ensure HTTPS is used

const AviatorGame = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [multiplier, setMultiplier] = useState(1.0);
  const [isCrashed, setIsCrashed] = useState(false);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [history, setHistory] = useState([]); // Store game history
  const [playerActions, setPlayerActions] = useState([]); // Store player actions for the sidebar

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    // Socket event listeners
    socket.on('countdownUpdate', (timeLeft) => {
      setCountdown(timeLeft);
      setIsGameRunning(false);
    });
  
    socket.on('multiplierUpdate', (newMultiplier) => {
      setMultiplier(newMultiplier);
      setIsGameRunning(true);
      setIsCrashed(false);
      setCountdown(null);
    });
  
    socket.on('gameCrashed', (crashMultiplier) => {
      setIsCrashed(true);
      setIsGameRunning(false);
      setHistory((prevHistory) => [crashMultiplier, ...prevHistory.slice(0, 19)]);
      setTimeout(() => {
        setPlayerActions([]); // Clear player actions after crash
      }, 1000); // Wait for 1 second before resetting
    });
  
    socket.on('playerAction', (action) => {
      setPlayerActions((prevActions) => [action, ...prevActions].slice(0, 10)); // Keep the last 10 actions
    });
  
    return () => {
      socket.off('countdownUpdate');
      socket.off('multiplierUpdate');
      socket.off('gameCrashed');
      socket.off('playerAction');
    };
  }, []);
  
  useEffect(() => {
    if (videoRef.current) {
      if (isCrashed) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      } else if (isGameRunning) {
        videoRef.current.play();
      } else {
        videoRef.current.load();
      }
    }
  }, [isCrashed, isGameRunning]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Handle player actions from PlayerManager and pass to backend
  const handlePlayerAction = (action) => {
    console.log('Player action:', action);

    // Emit player action to backend
    if (action.type === 'placeBet') {
      socket.emit('placeBet', action);
    } else if (action.type === 'cashOut') {
      socket.emit('cashOut', action);
    }

    // Update the player actions in the state for Sidebar
    setPlayerActions((prevActions) => [action, ...prevActions].slice(0, 10)); // Keep the last 10 actions
  };

  return (
    <div className="app-container">
      {/* Background video */}
      <video
        ref={videoRef}
        className="background-video"
        src="/videos/simulator.mp4"
        autoPlay
        muted
        loop={false}
      />

      {/* Floating logout button with icon */}
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt size={24} />
      </button>

      {/* NavBar with dynamic content (multiplier, countdown, and history) */}
      <NavBar multiplier={multiplier} countdown={countdown} history={history} />

      <div className="main-content">
        <Sidebar playerActions={playerActions} />
        <div className="game-section">
          <GameCanvas multiplier={multiplier} isCrashed={isCrashed} />
          <div className="game-status">
            {isGameRunning ? (
              <p style={{ fontSize: '48px', color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
                {multiplier.toFixed(2)}x
              </p>
            ) : (
              <p style={{ fontSize: '48px', color: 'green', textAlign: 'center', fontWeight: 'bold' }}>
                Next in: {countdown}s
              </p>
            )}
          </div>
          <PlayerManager
            multiplier={multiplier}
            gameStatus={isGameRunning ? 'Playing' : 'Idle'}
            countdown={countdown}
            handlePlayerAction={handlePlayerAction}  // Pass down the handler function
          />
        </div>
      </div>
    </div>
  );
};

export default AviatorGame;
