import React from 'react';
import './App.css';

const Sidebar = ({ playerActions }) => {
  const formatMultiplier = (multiplier) => {
    // Ensure multiplier is a valid number and round it to 2 decimal places
    const num = parseFloat(multiplier);
    return isNaN(num) ? '0.00' : num.toFixed(2);
  };

  return (
    <div className="sidebar">
      <h2>Player</h2>
      <ul>
        {playerActions.map((action, index) => (
          <li key={index}>
            Player {action.playerId} {action.action} {action.betAmount ? `Ksh ${action.betAmount}` : ''} at {formatMultiplier(action.multiplier)}x
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
