import React, { useState } from 'react';
import { loginUser } from '../api';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
// src/LoginPage.js

const handleLogin = async (e) => {
  e.preventDefault();
  try {
    const response = await loginUser(email, pin);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('userId', response.data.userId); // Save userId to localStorage
    navigate('/aviator');
  } catch (error) {
    setMessage('Login failed: ' + (error.response?.data?.message || error.message));
  }
};

  return (
    <div className="auth-container">
      <h1 className="auth-title">Login</h1>
      <form onSubmit={handleLogin} className="auth-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="PIN"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default LoginPage;
