import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlayerPanel from './PlayerPanel';
import { io } from 'socket.io-client';

const api = axios.create({
  baseURL: 'https://rocketaviator.com/api',
  headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Setup socket connection to backend
const socket = io('https://rocketaviator.com', {
  auth: { token: localStorage.getItem('token') },
});

const PlayerManager = ({ handlePlayerAction }) => {
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(null);
  const [gameStatus, setGameStatus] = useState('Waiting');
  const [multiplier, setMultiplier] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fetch players from the API
  const fetchPlayers = async () => {
    setIsRefreshing(true);
    setError(null);
    try {
      const response = await api.get('/get-players');
      if (response.data && Array.isArray(response.data)) {
        setPlayers(response.data); // Set players only if response is valid
      } else {
        setError('Invalid player data received.');
      }
    } catch (err) {
      console.error('Error fetching players:', err.response?.data || err.message);
      setError('Failed to load player data. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchPlayers();

    // Set up socket listeners
    socket.on('countdownUpdate', () => {
      setGameStatus('Countdown');
      setMultiplier(1);
    });

    socket.on('multiplierUpdate', (newMultiplier) => {
      setGameStatus('Playing');
      setMultiplier(newMultiplier);
    });

    socket.on('gameEnded', () => {
      setGameStatus('Waiting');
      fetchPlayers(); // Refresh players after the game ends
    });

    socket.on('gameCrashed', () => {
      setGameStatus('Waiting');
      fetchPlayers(); // Refresh players after the game crashes
    });

    socket.on('playerUpdated', (updatedPlayer) => {
      setPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.player_id === updatedPlayer.playerId
            ? {
                ...player,
                balance: updatedPlayer.balance,
                status: updatedPlayer.status,
                win: updatedPlayer.win,
              }
            : player
        )
      );
    });

    // Cleanup on unmount
    return () => {
      socket.off('countdownUpdate');
      socket.off('multiplierUpdate');
      socket.off('gameEnded');
      socket.off('gameCrashed');
      socket.off('playerUpdated');
    };
  }, []);

  // Betting logic: This sends the bet action to the parent (AviatorGame)
  const placeBet = async (playerId, betAmount) => {
    if (gameStatus !== 'Countdown') {
      setError('You can only place bets during the countdown.');
      return;
    }

    try {
      // Send bet details to the backend to process the bet
      const response = await api.post('/bet/place-bet', { playerId, betAmount });
      if (response.data.success) {
        setPlayers((prevPlayers) =>
          prevPlayers.map((player) =>
            player.player_id === playerId
              ? { ...player, balance: response.data.balance, status: 'Bet Placed', betAmount }
              : player
          )
        );

        // Emit the bet action to the backend and broadcast it to other clients
        handlePlayerAction({ type: 'placeBet', playerId, betAmount, multiplier });
      } else {
        setError('Failed to place bet. Please try again.');
      }
    } catch (err) {
      console.error('Error placing bet:', err.response?.data || err.message);
      setError('Failed to place bet. Please try again.');
    }
  };

  // Handle cash out action
  const cashOut = async (playerId) => {
    try {
      const response = await api.post('/bet/cash-out', { playerId, multiplier });
      if (response.data.success) {
        setPlayers((prevPlayers) =>
          prevPlayers.map((player) =>
            player.player_id === playerId
              ? {
                  ...player,
                  balance: response.data.balance,
                  status: 'Cashed',
                  win: response.data.win,
                }
              : player
          )
        );
        // Emit cash out action to the backend
        handlePlayerAction({ type: 'cashOut', playerId, multiplier });
      }
    } catch (err) {
      console.error('Error cashing out:', err.response?.data || err.message);
      setError('Failed to cash out. Please try again.');
    }
  };

  // Check if players array is populated before rendering
  if (!players || players.length === 0) {
    return <p>Loading players...</p>;
  }

  return (
    <div className="player-manager">
      {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
      <div style={{ marginBottom: '10px' }}>
        <button onClick={fetchPlayers} disabled={isRefreshing}>
          {isRefreshing ? 'Refreshing...' : 'Refresh Players'}
        </button>
      </div>
      <div className="player-panels">
        {players.map((player, index) => (
          <PlayerPanel
            key={player.player_id}
            player={player}
            placeBet={(betAmount) => placeBet(player.player_id, betAmount)}
            cashOut={() => cashOut(player.player_id)}
            gameStatus={gameStatus}
            multiplier={multiplier}
            index={index}
            resetGame={gameStatus === 'Waiting'}
            timer={multiplier}
          />
        ))}
      </div>
    </div>
  );
};

export default PlayerManager;
