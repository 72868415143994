import React, { useState, useEffect, useRef, useMemo } from "react";
import { FaMouse } from "react-icons/fa"; // FontAwesome Mouse Icon

const PlayerPanel = React.memo(
  ({
    player = {},
    gameStatus = "Idle",
    multiplier = 1,
    index = 0,
    resetGame = false,
    placeBet = () => {},
    cashOut = () => {},
    setMouseOwner = () => {},
    setActiveMouse = () => {},
  }) => {
    const [betAmount, setBetAmount] = useState(10); // Default bet amount
    const [finalWin, setFinalWin] = useState(0);
    const [balanceBeforeCashout, setBalanceBeforeCashout] = useState(player.balance || 0);
    const [isLocked, setIsLocked] = useState(false); // Pointer lock state

    const cursorRef = useRef(null);
    const panelRef = useRef(null);

    // Define unique colors for players
    const colorPalette = [
      "#FF6347", "#32CD32", "#1E90FF", "#FF1493", "#FFD700", "#8A2BE2", "#00CED1", "#FF4500", "#D2691E", "#FF69B4",
    ];
    const playerIdleColor = colorPalette[index % colorPalette.length];

    // Save bet amount to localStorage
    useEffect(() => {
      if (player.player_name) {
        localStorage.setItem(`betAmount_${player.player_name}`, betAmount);
      }
    }, [betAmount, player.player_name]);

    // Initialize and set up a checker for mouse position
    useEffect(() => {
      const checkAndRelock = () => {
        if (!isLocked && panelRef.current) {
          const panelBounds = panelRef.current.getBoundingClientRect();
          const cursorX = window.event?.clientX || 0;
          const cursorY = window.event?.clientY || 0;

          // Check if the cursor is within the panel bounds
          if (
            cursorX >= panelBounds.left &&
            cursorX <= panelBounds.right &&
            cursorY >= panelBounds.top &&
            cursorY <= panelBounds.bottom
          ) {
            panelRef.current?.requestPointerLock();
            setIsLocked(true);
            setMouseOwner(player.player_id); // Set mouse ownership
          }
        }
      };

      const interval = setInterval(checkAndRelock, 2000); // Check every 2 seconds
      return () => clearInterval(interval); // Cleanup interval on unmount
    }, [isLocked, player.player_id, setMouseOwner]);

    // Calculate potential win
    const potentialWin = useMemo(() => {
      if (player.status === "Bet Placed" && player.betAmount) {
        return player.betAmount * multiplier;
      }
      return 0;
    }, [player.status, player.betAmount, multiplier]);

    // Update final win and balance after cashout
    useEffect(() => {
      if (player.status === "Cashed" && potentialWin > 0) {
        setFinalWin(potentialWin);
        setBalanceBeforeCashout(player.balance || 0);
      }
    }, [player.status, potentialWin, player.balance]);

    // Reset final win and balance on game restart
    useEffect(() => {
      if (resetGame) {
        setFinalWin(0);
        setBalanceBeforeCashout(player.balance || 0);
        setIsLocked(false);
        document.exitPointerLock();
      }
    }, [resetGame, player.balance]);

    // Handle pointer lock change
    useEffect(() => {
      const handlePointerLockChange = () => {
        const locked = document.pointerLockElement === panelRef.current;
        setIsLocked(locked);
        if (!locked) setActiveMouse(null); // Release active mouse when unlocked
      };

      document.addEventListener("pointerlockchange", handlePointerLockChange);
      return () => {
        document.removeEventListener("pointerlockchange", handlePointerLockChange);
      };
    }, [setActiveMouse]);

    // Mouse movement tracking
    const handleMouseMove = (e) => {
      if (isLocked && panelRef.current) {
        const panelBounds = panelRef.current.getBoundingClientRect();
        const mouseX = Math.max(0, Math.min(e.movementX, panelBounds.width));
        const mouseY = Math.max(0, Math.min(e.movementY, panelBounds.height));
        if (cursorRef.current) {
          cursorRef.current.style.left = `${mouseX}px`;
          cursorRef.current.style.top = `${mouseY}px`;
        }
      }
    };

    useEffect(() => {
      if (isLocked) {
        document.addEventListener("mousemove", handleMouseMove);
      }
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }, [isLocked]);

    // Handle mouse wheel to adjust bet amount
    const handleMouseWheel = (e) => {
      e.preventDefault();
      const increment = e.deltaY > 0 ? -10 : 10;
      setBetAmount((prev) => Math.max(10, prev + increment)); // Minimum bet amount is 10
    };

    // Handle panel click for locking and placing bets
    const handlePanelClick = () => {
      if (player.status === "Idle" && gameStatus !== "Running") {
        placeBet(betAmount);
        setIsLocked(true);
        setMouseOwner(player.player_id);
        panelRef.current?.requestPointerLock();
      } else if (player.status === "Bet Placed") {
        cashOut();
      }
    };

    // Handle ESC key to release pointer lock
    const handleEscPress = (e) => {
      if (e.key === "Escape" && isLocked) {
        document.exitPointerLock();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleEscPress);
      return () => {
        document.removeEventListener("keydown", handleEscPress);
      };
    }, [isLocked]);

    // Determine the background color of the panel based on player status
    const getPanelColor = () => {
      if (player.status === "Bet Placed") return "#00BFFF";
      if (player.status === "Cashed") return "#32CD32";
      if (["Failed", "Crushed"].includes(player.status)) return "#FF0000";
      return playerIdleColor;
    };

    return (
      <div
        ref={panelRef}
        className="player-panel"
        style={{
          backgroundColor: getPanelColor(),
          color: "#fff",
          fontSize: "16px",
          padding: "12px",
          borderRadius: "8px",
          width: "250px",
          height: "200px",
          position: "relative",
          cursor: isLocked ? "none" : "default",
          overflow: "hidden",
        }}
        onClick={handlePanelClick}
        onWheel={handleMouseWheel}
      >
        {/* Player Name */}
        <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
          {player.player_name || "Player"}
        </div>

        {/* Mouse Icon */}
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            color: isLocked ? "#FFD700" : "#fff",
            zIndex: 10,
          }}
        >
          <FaMouse />
        </div>

        {/* Virtual Cursor */}
        {isLocked && (
          <div
            ref={cursorRef}
            style={{
              position: "absolute",
              width: "20px",
              height: "20px",
              backgroundColor: "#FFD700",
              borderRadius: "50%",
              pointerEvents: "none",
            }}
          />
        )}

        {/* Bet Input */}
        <input
          type="number"
          value={betAmount}
          onChange={(e) => setBetAmount(parseInt(e.target.value, 10))}
          disabled={gameStatus === "Running"}
          style={{
            width: "100%",
            padding: "5px",
            borderRadius: "5px",
          }}
        />

        <p>Balance: Ksh {parseFloat(balanceBeforeCashout + finalWin).toFixed(2)}</p>
        <p>Potential Win: Ksh {potentialWin.toFixed(2)}</p>
      </div>
    );
  }
);

export default PlayerPanel;
